import { useEffect } from "react";

import { Props } from "../../types/common";

import useAuthenticationStore from "~/src/store/authenticationDashboard";

const TokenValidator = ({ children }: Props) => {
  const { setToken, setIsLoggedIn } = useAuthenticationStore();

  const checkToken = () => {
    const accessTokenLocalStorage = localStorage.getItem("google-access-token");
    const accessTokenHashParams = window?.location?.hash?.match(/access_token=(.*?)(&|$|\/)/)?.[1];
    const token = accessTokenHashParams || accessTokenLocalStorage;
    if (!token) {
      localStorage.removeItem("google-access-token");
      setIsLoggedIn(false);
    } else {
      if (accessTokenHashParams) localStorage.setItem("google-access-token", accessTokenHashParams);
      setToken(token);
      setIsLoggedIn(true);
    }
  };

  useEffect(() => {
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};

export default TokenValidator;
