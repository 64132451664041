import React from "react";

import "react-day-picker/lib/style.css";
import DashboardPage from "./dashboard";

function IndexPage(): JSX.Element {
  return <DashboardPage />;
}

export default IndexPage;
