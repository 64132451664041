import React from "react";

import PrivateRoute from "../components/Authentication/DashboardPrivateRoute";
import TokenValidator from "../components/Authentication/DashboardTokenValidator";
import Layout from "../components/Layout";

import SEO from "~/src/components/SEO";
import "react-day-picker/lib/style.css";

const DashboardPage = (): JSX.Element => {
  return (
    <Layout>
      <TokenValidator>
        <PrivateRoute>
          <>
            <SEO title="Examedi Marketing Dashboard" />
            <div className="min-h-screen w-full bg-gray-100 overflow-y-auto p-4">
              hola mkt, les amamos. atentamente les devs
            </div>
          </>
        </PrivateRoute>
      </TokenValidator>
    </Layout>
  );
};

export default DashboardPage;
