import React from "react";

import { Props } from "../../types/common";
import GoogleAuth from "../GoogleAuth";

import useAuthenticationGoogleStore from "~/src/store/authenticationDashboard";

const PrivateGoogleRoute = ({ children }: Props): JSX.Element | React.ReactChild | React.ReactChildren => {
  const { isLoggedIn } = useAuthenticationGoogleStore();

  return isLoggedIn ? children : <GoogleAuth />;
};

export default PrivateGoogleRoute;
