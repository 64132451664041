import axios from "axios";
import qs from "qs";

import { DateRangeInput } from "~/src/types";
import { parseDate } from "~/src/utils/date";
import namespaced from "~/src/utils/debug";

const debug = namespaced("api-google");
const GOOGLE_TABLE_ID = "ga:237385876";
const GOOGLE_CLIENT_ID = "916108369434-ghivvo6lspoboquft0sf5ihcbnvqom18.apps.googleusercontent.com";

const client = axios.create({ baseURL: "https://www.googleapis.com/" });

type GCCH = { name: string; columnType: string; dataType: string };
type GoogleCampaignsCustomQuery = {
  kind: string;
  id: string;
  query: Record<string, any>;
  itemsPerPage: number;
  totalResults: number;
  selfLink: string;
  profileInfo: Record<string, string>;
  containsSampledData: boolean;
  columnHeaders: [GCCH, GCCH, GCCH, GCCH, GCCH, GCCH];
  totalsForAllResults: Record<string, string>;
  rows: [string, string, string, string, string, string][];
};
export type GoogleCampaignsPayload = Pick<
  GoogleCampaignsCustomQuery,
  "columnHeaders" | "totalsForAllResults" | "rows" | "query"
>;

export async function getCampaignsFromGoogle({
  accessToken,
  startDate,
  endDate,
}: { accessToken: string } & DateRangeInput): Promise<GoogleCampaignsPayload | 401 | undefined> {
  try {
    const res = await client.get<GoogleCampaignsCustomQuery>(
      `analytics/v3/data/ga?${qs.stringify(
        {
          access_token: accessToken,
          ids: GOOGLE_TABLE_ID,
          dimensions: "ga:campaign,ga:adGroup",
          metrics: "ga:impressions,ga:adClicks,ga:adCost,ga:costPerConversion",
          sort: "-ga:impressions",
          filters: "ga:impressions>0",
          "start-date": parseDate(startDate),
          "end-date": parseDate(endDate),
        },
        { addQueryPrefix: false },
      )}`,
    );
    debug("getCampaignsFromGoogle response was", res.status);
    if (res.status !== 200) return undefined;
    return res.data;
  } catch (err: any) {
    if (err.response?.status && err.response.status === 401) return 401;
    debug(err);
  }
}

export const googleAuthentication = () => {
  const params: Record<string, string> = {
    client_id: GOOGLE_CLIENT_ID,
    redirect_uri: "https://melvin.examedi.com",
    scope: "https://www.googleapis.com/auth/analytics.readonly",
    response_type: "token",
  };
  const form = document.createElement("form");
  form.setAttribute("method", "GET");
  form.setAttribute("action", "https://accounts.google.com/o/oauth2/v2/auth");
  for (const p in params) {
    const input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", p);
    input.setAttribute("value", params[p]);
    form.appendChild(input);
  }
  document.body.appendChild(form);
  form.submit();
};
