import React, { useState } from "react";

import LoadingIcon from "../icons/loading";
import { googleAuthentication } from "../utils/api-google";

function GoogleAuth(): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const OAuth2SignIn = () => {
    setIsLoading(true);
    googleAuthentication();
  };

  return (
    <div className="bg-gray-100 w-full min-h-screen p-2">
      <div className="bg-white shadow rounded-lg p-4 col-span-10 xl:col-span-5 m-3">
        <div className="flex items-center justify-between">
          <div className="flex justify-between min-w-full">
            <h3 className="text-xl font-bold text-gray-900 mb-2 inline">
              {
                <>
                  <span>Hey 👋🏻</span>
                  <span className="ml-4">Security check</span>
                  <span className="ml-4 text-2xl">🥷</span>
                </>
              }
            </h3>
            {isLoading && <LoadingIcon />}
          </div>
        </div>
        <div>
          {!isLoading && (
            <button
              className="bg-gray-800 hover:bg-gray-600 text-white font-bold py-2 px-7 rounded"
              onClick={OAuth2SignIn}
            >
              Sign in
            </button>
          )}
          {isLoading && <LoadingIcon />}
        </div>
      </div>
    </div>
  );
}

export default GoogleAuth;
